import { Calculator, House, UserList } from "phosphor-react";
import { Route, Routes } from "react-router-dom";
import Home from "../../pages/Home";
import { ListaUsuarios } from "../../pages/UsersList";
import NotFound from "../NotFound";
import PlanCalculator from "../../pages/PlanCalculator";

import { useContext } from "react";
import AuthContext from "../../contexts/auth";
import Login from "../../pages/Login";
import UpdateUser from "../../pages/UpdateUser";
export interface RouteProps {
  to: string;
  name?: string;
  icon?: React.ReactNode;
  element: React.ReactNode;
}
export const routeArr: RouteProps[] = [
  {
    name: "Home",
    to: "/",
    icon: <House size={20} />,
    element: <Home />,
  },
  {
    name: "Usuários",
    to: "/users",
    icon: <UserList size={20} />,
    element: <ListaUsuarios />,
  },
  {
    name: "Calc. Planos",
    to: "/plan-calculator",
    icon: <Calculator size={20} />,
    element: <PlanCalculator />,
  },
];

function Router() {
  const context = useContext(AuthContext);
  return (
    <Routes>
      {context.signed ? (
        routeArr.map((item, index) => {
          return <Route path={item.to} element={item.element} key={index} />;
        })
      ) : (
        <Route path="/login" element={<Login />} />
      )}
      <Route path="/updateUser/:id" element={<UpdateUser />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default Router;
