export type PlanType = "BASIC" | "PRO";
export type BillingType = "MONTHLY" | "ANNUAL";

interface PlanPricing {
  basePrice: number;
  keyPricing: {
    upTo10: number;
    from11To50: number;
    from51To100: number;
    above100: number;
  };
}

const PLAN_PRICING: Record<PlanType, PlanPricing> = {
  BASIC: {
    basePrice: 29.9,
    keyPricing: {
      upTo10: 20,
      from11To50: 15,
      from51To100: 12,
      above100: 10,
    },
  },
  PRO: {
    basePrice: 49.9,
    keyPricing: {
      upTo10: 30,
      from11To50: 25,
      from51To100: 20,
      above100: 15,
    },
  },
};

const ANNUAL_DISCOUNT = 0.5; // 50% de desconto
export const MONTHLY_INTEREST_RATE = 0.016666; // 1.6666% ao mês

export function calculateInterestFactor(numberOfInstallments: number): number {
  if (numberOfInstallments <= 1) return 1;

  // Fator = (1 + taxa * número de parcelas)
  // Arredonda para 4 casas decimais para manter a precisão
  return Number((1 + MONTHLY_INTEREST_RATE * numberOfInstallments).toFixed(4));
}

export interface CalculatePlanParams {
  planType: PlanType;
  numberOfKeys: number;
  billingType: BillingType;
  numberOfInstallments?: number; // Opcional, padrão é 1 para mensal e 12 para anual
}

export interface PlanCalculationResult {
  totalPriceWithInterests: number; // Preço total com juros
  pricePerMonth: number; // Valor da parcela
  numberOfKeys: number;
  planType: PlanType;
  billingType: BillingType;
  anchorPrice?: number; // Preço cheio antes do desconto
  numberOfInstallments: number;
  productPrice: number; // Preço original sem juros
}

function calculateKeyPrice(numberOfKeys: number, pricing: PlanPricing): number {
  let totalPriceWithInterests = pricing.basePrice;

  for (let i = 1; i <= numberOfKeys; i++) {
    if (i <= 10) {
      totalPriceWithInterests += pricing.keyPricing.upTo10;
    } else if (i <= 50) {
      totalPriceWithInterests += pricing.keyPricing.from11To50;
    } else if (i <= 100) {
      totalPriceWithInterests += pricing.keyPricing.from51To100;
    } else {
      totalPriceWithInterests += pricing.keyPricing.above100;
    }
  }

  return totalPriceWithInterests;
}

export function calculatePlan({
  planType,
  numberOfKeys,
  billingType,
  numberOfInstallments,
}: CalculatePlanParams): PlanCalculationResult {
  if (numberOfKeys < 1) {
    throw new Error("O número de chaves deve ser maior que zero");
  }

  if (
    billingType === "MONTHLY" &&
    numberOfInstallments &&
    numberOfInstallments > 1
  ) {
    throw new Error("Não é possível parcelar planos mensais");
  }

  const pricing = PLAN_PRICING[planType];
  const monthlyPrice = calculateKeyPrice(numberOfKeys, pricing);

  // Define o número de parcelas padrão
  const installments =
    numberOfInstallments || (billingType === "ANNUAL" ? 12 : 1);

  // Calcula o preço base com desconto anual se aplicável
  const basePrice =
    billingType === "ANNUAL"
      ? monthlyPrice * 12 * (1 - ANNUAL_DISCOUNT)
      : monthlyPrice;

  // Aplica o fator de juros de acordo com o número de parcelas
  const factor = calculateInterestFactor(installments);
  const totalPriceWithInterestsWithInterest = basePrice * factor;

  const result: PlanCalculationResult = {
    totalPriceWithInterests: totalPriceWithInterestsWithInterest,
    pricePerMonth: totalPriceWithInterestsWithInterest / installments,
    numberOfKeys,
    planType,
    billingType,
    numberOfInstallments: installments,
    productPrice: basePrice,
  };

  // Adiciona anchorPrice apenas se tiver mais de uma chave
  if (numberOfKeys > 1) {
    const oneKey = pricing.basePrice + pricing.keyPricing.upTo10;
    const anchorPrice =
      oneKey * numberOfKeys * (billingType === "ANNUAL" ? 12 : 1);
    result.anchorPrice = anchorPrice;
  }

  return result;
}
