import { Typography, Form, InputNumber, Select, Card, Space } from "antd";
import { useState, useEffect } from "react";
import { Container } from "./styles";
import {
  calculatePlan,
  PlanType,
  BillingType,
  CalculatePlanParams,
  PlanCalculationResult,
} from "../../libs/calculate-plan";

export function PlanCalculator() {
  const [form] = Form.useForm();
  const [result, setResult] = useState<PlanCalculationResult | null>(null);

  const installmentOptions = Array.from({ length: 12 }, (_, i) => ({
    value: i + 1,
    label: `${i + 1}x`,
  }));

  const formatMessage = (result: PlanCalculationResult) => {
    const period = result.billingType === "MONTHLY" ? "mensal" : "anual";
    const planName = result.planType === "BASIC" ? "Básico" : "Pro";
    const hasInstallments = result.numberOfInstallments > 1;
    const anchorPart = result.anchorPrice
      ? `~R$ ${result.anchorPrice.toFixed(2)}~`
      : "";

    if (hasInstallments) {
      return `${
        result.numberOfKeys
      } números no plano ${planName} ${period} sai ${
        anchorPart ? `de ${anchorPart} ` : ""
      }por ${result.numberOfInstallments}x R$ ${result.pricePerMonth.toFixed(
        2
      )}`;
    }

    return `${result.numberOfKeys} números no plano ${planName} ${period} sai ${
      anchorPart ? `de ${anchorPart} ` : ""
    }por R$ ${result.pricePerMonth.toFixed(2)}`;
  };

  const handleCalculate = (values: CalculatePlanParams) => {
    try {
      const calculation = calculatePlan(values);
      setResult(calculation);
    } catch (error) {
      // TODO: Adicionar tratamento de erro adequado
      console.error(error);
    }
  };

  // Recalcula sempre que os valores do formulário mudarem
  useEffect(() => {
    const values = form.getFieldsValue();
    if (values.numberOfKeys && values.planType && values.billingType) {
      // Se for mensal, força número de parcelas para 1
      if (values.billingType === "MONTHLY") {
        values.numberOfInstallments = 1;
      }
      // Se for anual e não tiver parcelas definidas, define como 1
      else if (!values.numberOfInstallments) {
        values.numberOfInstallments = 1;
      }
      handleCalculate(values);
    }
  }, [form.getFieldsValue()]);

  return (
    <Container>
      <Typography.Title level={2}>Calculadora de Planos</Typography.Title>

      <Card style={{ maxWidth: 600, margin: "0 auto" }}>
        <Form
          form={form}
          layout="vertical"
          initialValues={{
            planType: "BASIC",
            billingType: "MONTHLY",
            numberOfKeys: 1,
            numberOfInstallments: 1,
          }}
          onValuesChange={() => {
            const values = form.getFieldsValue();
            if (values.numberOfKeys && values.planType && values.billingType) {
              if (values.billingType === "MONTHLY") {
                values.numberOfInstallments = 1;
              }
              handleCalculate(values);
            }
          }}
        >
          <Form.Item
            label="Tipo do Plano"
            name="planType"
            rules={[{ required: true }]}
          >
            <Select>
              <Select.Option value="BASIC">Básico</Select.Option>
              <Select.Option value="PRO">Pro</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="Período de Cobrança"
            name="billingType"
            rules={[{ required: true }]}
          >
            <Select>
              <Select.Option value="MONTHLY">Mensal</Select.Option>
              <Select.Option value="ANNUAL">Anual</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="Quantidade de Chaves"
            name="numberOfKeys"
            rules={[{ required: true }]}
          >
            <InputNumber min={1} style={{ width: "100%" }} />
          </Form.Item>

          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.billingType !== currentValues.billingType
            }
          >
            {({ getFieldValue }) =>
              getFieldValue("billingType") === "ANNUAL" && (
                <Form.Item
                  label="Número de Parcelas"
                  name="numberOfInstallments"
                  rules={[{ required: true }]}
                >
                  <Select options={installmentOptions} />
                </Form.Item>
              )
            }
          </Form.Item>
        </Form>

        {result && (
          <>
            <Card title="Resultado do Cálculo" style={{ marginTop: 16 }}>
              <Space direction="vertical" style={{ width: "100%" }}>
                <Typography.Text>
                  Valor Total: R$ {result.totalPriceWithInterests.toFixed(2)}
                </Typography.Text>
                <Typography.Text>
                  Valor Mensal: R$ {result.pricePerMonth.toFixed(2)}
                </Typography.Text>
                {result.anchorPrice && (
                  <Typography.Text>
                    Preço de Ancoragem: R$ {result.anchorPrice.toFixed(2)}
                  </Typography.Text>
                )}
                <Typography.Text>
                  Número de Parcelas: {result.numberOfInstallments}
                </Typography.Text>
              </Space>
            </Card>

            <Card title="Mensagem para o Cliente" style={{ marginTop: 16 }}>
              <Typography.Text copyable style={{ fontSize: 16 }}>
                {formatMessage(result)}
              </Typography.Text>
            </Card>
          </>
        )}
      </Card>
    </Container>
  );
}

export default PlanCalculator;
