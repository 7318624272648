import styled from "styled-components";

export const Container = styled.div`
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;

  .ant-typography {
    text-align: center;
    margin-bottom: 2rem;
  }

  .ant-card {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }

  .ant-form {
    .ant-form-item:last-child {
      margin-bottom: 0;
    }
  }
`;
